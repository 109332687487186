@import "../../../../../../../../stylesheets/modules/styles/modules/pos/master/trans-manager/pos-trans-manager-list/_pos-trans-manager-list";

.pos-trans-manager-list {
	
	&__bookings {
	    &_row {
	    	&:hover {
				background-color: $brand-color-36;
	    	}
    	}
	}
	
	&__bookings_row:hover &__details:nth-child(even) {
		background-color: $brand-color-36;
	}
	
	&__bookings_row:hover &__details:nth-child(odd) {
		background-color: $brand-color-36;
	}
		
	&__content{
		&_headers{
			background-color: $brand-color-36;
		    color: $brand-color-34;
		}
	}
	
	&__transaction-count{
		margin-top:0.625rem;
		margin-bottom:0.625rem;
		width: 25%;
   float: left;
	}
}