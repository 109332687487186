@import "../../../../../../../stylesheets/modules/styles/modules/pos/commons/pos-header/pos-header";

.pos-header {
    background-color: var(--header-bg-color);
    min-height: 100px;

	&__title {
	    color: $brand-color-11;
	}
	
	&__logo {
		img {
			max-height: 100px;
		}
	}
	&__name{
	    color : var(--header-font-color);
	    font-size: 1.25rem;
        align-self: center;
    	font-weight: 600;
	}
	
	&__text{
	    display: inline-block;
	    color : var(--header-font-color);
	    vertical-align: bottom;
	    font-size: 1rem;
        margin-top: 0.375rem;
        line-height: 1.5rem;
		margin-right: auto;
	}
	
	&__number{
	    display: inline-block;
	    color : var(--header-font-color);
	    vertical-align: bottom;
	    font-size: 1rem;
        margin-top: 0.375rem;
	}
	
	&__helpLineNo{
	    display: inline-block;
	    color : var(--header-font-color);
	    vertical-align: bottom;
	    font-size: 1.3rem;
        margin-top: 0.375rem;
	}
	&__navigation{
        &_nav-links {
            font-family: $font-family-landing-page-sans-serif;
            display: flex;
            padding: 1rem;
            .nav-links {
                &__login,
                &__registration,
                &__youtube,
				&__zoho-insurance {
                        margin-right: 2rem;
                        .routed-popover__route-link, 
                        &_link {
						cursor: pointer;
                        font-size: 1.25rem;
                        font-weight: 600;
                        text-decoration: none;
                        color: var(--header-font-color);
                        &:after {
                            content: "";
                            display: block;
                            border-bottom: 2px solid var(--header-font-color);
                            transform: scaleX(0);
                            transition: transform 250ms ease-in-out;
                            padding-bottom: 0.5rem;
                        }
                        &:hover:after {
                            transform: scaleX(1);
                        }
                    }
                }
                &__youtube {
                    &_link {
                        margin-right: 0;
                        font-size: 2rem;
                        .youtube-channel-icon {
                            display: block;
                            background-image: url(../images/yt_logo_rgb_light.png);
                            background-repeat: no-repeat;
                            background-size: 100%;
                            width: 100px;
                            height: 30px;
                            background-position: top;
                        }
                    }
                }
				&__zoho-insurance > a {
					cursor: pointer;
                        font-size: 1.25rem;
                        font-weight: 600;
                        text-decoration: none;
                        color: var(--pos-login-insurance-link-color);
				}
            }
        }
        &_auth-nav {
            font-size: 1rem;
            .auth-nav__user-name {
                color: var(--header-font-color);
            }
            .auth-nav__balance {
                &_data {
                    color: var(--header-font-color);
                }
            }
        }

	}
	
	.login__popover {
		&_user-id-arrow{
			@include make-triangle(bottom, 5px,var(--header-font-color));
		}
	}
	.popover {
		.login__account_links {
			> a {
				color: var(--header-font-color);
			}
		} 
	} 
}
