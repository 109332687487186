@mixin make-user-details-container_private {

	&__content{
	    float: left;
	    width: 100%;
	    background-color: $gray-lighter;
	    min-height: 35rem;
	    
		&_odd-container{
	    	@include make-one-half;
	   		padding: 1rem 1rem 0 0.5rem;		
		}	
		
		&_even-container{
		    @include make-one-half;
		    padding: 1rem 0.5rem 0 1rem;		
		}	
	
		&_data{
		    @include make-row;
    		padding: 0;
		    margin: 0;
    		margin-bottom: 1rem;
    		background-color: $brand-color-11;
    		box-shadow: 0 0 10px rgba(0, 0, 0, .25);
    		width: 100%;
    		float: left;
		}		
		
		&_no-data{
			@include make-row;
		    @include clearfix;
    		padding: 0 calc($base-padding/2);
    		margin-bottom: calc($base-margin/2);
    		background-color: $light;
    		box-shadow: 0 0 10px rgba(0, 0, 0, .25);		
			text-align: center;
   			 height: 35px;
		}
	
	}	
	
}

@mixin make-personal-details-container_private {

		@include make-row;
        margin-left: 0;
	    margin-right: 0;
   	    padding: calc($base-padding/3);
   	    
		&__image{
			@include make-one-fourth;
		    border: 3px solid $brand-color-4;
		    padding: calc($base-padding/6);
	        text-align: center;
	        width:20%;
	        color: $gray-lighting;
		}

		&__personal-details{
			@include make-three-fourth;
		    margin-top: -6px;
	        padding-right: 0;
	        width: 80%;
		    &_user{
		    	@include make-row;
		    }
		}

		.user-controls{
			padding: 0;
		    float: right;
		    width:40%;
	        min-height: 1px;

			&__container {
				float: right;
				cursor: pointer;
				
				&_activate{
					float: right;
					margin-top: 3px;
					margin-left: calc($base-margin/5);
					cursor: pointer;				
				}			
				&_edit{
					cursor: pointer;
				}			
				&_transaction{
					font-size: 18px;
					margin-left: calc($base-margin/11);
				}			
			}
			&_is-active{
			  display:inline-block;
			  position: relative;		
			}
			
		}

		.personal-details{
			@include make-three-fifth;
		    @include clearfix;	
		    width:60%;
		    float: left;
	        min-height: 1px;
	        padding-left: 0;
		    	
			&__name{
			    font-size: 20px;
   				color: $brand-color-2;
			    white-space: nowrap;
 	 			overflow: hidden;
  				text-overflow: ellipsis;
				width: 85%;
 			    float: left;
			}
	    
		}	
	
}

@mixin make-action-bar_private{
	
	&_action-bar {
	    display : table-cell;
		width: 40%;
		padding-top: calc($base-padding/3);
		
		.action-bar {
			@include clearfix;
			
			&__search {
				float: right;
				
				.pos-quick-search {
					float: right;
				}
				
				> label{
					color: $brand-color-2;
				}
			}
			&__form_back-list-title{
				padding-bottom: 10px;
   				width: 100%;
   				text-align: right;
   				font-size: 1rem;
			}
			&__add-button {
				float: right;
			    margin-left: 10px;
			    
				>button {
					@include make-button($brand-color-11, $brand-color-2, capitalize);
					@include button-size(10px ,10px, 15px, 0, 4px, 100%);
				    height: 1.875rem;
				}		    
			} 
		}
	}	
	
}

@mixin make-header-title_private{
	&_title , &_back-list-title{
		width: 60%;
		font-size: 2rem;
		color: $brand-color-37;
	    text-transform: capitalize;
	    display : table-cell;
	}
	&_back-list-title{
		width: 40%;
		text-align: right;
		font-size : 1rem;
	}	
}

@mixin  make-user-details-container { 
	@include make-user-details-container_private; 
}
@mixin  make-personal-details-container { 
	@include make-personal-details-container_private; 
}
@mixin  make-action-bar{ 
	@include make-action-bar_private; 
}
@mixin  make-header-title{ 
	@include make-header-title_private; 
}