.landing-page{
    width: 100%;
	height: 1200px;

    &__frame {
        width: 100%;
        height: 1200px;
		overflow: hidden;
        border: 0px;
        min-width: 1024px;
    }
}