.pos-task-form {
    @include create-row-card;
    @include create-card-content;
    @include create-form-row-details;

    &_card {
        box-shadow: $row-shadow-color;
        padding: calc($base-padding/2);
    }

    &_content {
        @include create-nav-tabs;
    }

    &__category,
    &__task-name,
    &__task-desc,
    &__assignee,
    &__status,
    &__bkg-ref,
    &__bkg-ref-product-detail,
    &__priority {
        @include clearfix;
        @include form-row-container-properties;
        width: 100%;
    }

	&__task-name,&__task-desc {
    	&_data {
           &:disabled {
   		   cursor: not-allowed;
           }
        }
    }
    
    &__status,
    &__priority {
        @include make-one-half;
        padding: 0.3rem calc($base-padding/3);

        @include make-responsive($desktop-sm) {
            width: 25%;
        }
    }
    
    &__due-date-time{
    	@include clearfix;
     	@include form-row-container-properties;
        width: 100%;
        float:left;
    	padding: .3rem 0rem;
        @include make-responsive($desktop-sm) {
           	width: 25%;
        }
    	&_due-date {
    		float:left;
        	width: 50%;
        	padding: 0rem .625rem;
        	@include make-responsive($desktop-sm) {
           		width: 65%;
        	}
       		label {
            	color: $brand-color-2;
            	margin-bottom: 0rem;
            	font-weight: normal;
        	}
    	}
    
    	&_due-time {
        	float: left;
    		width: 50%;
    		padding: 0rem .625rem;
    		@include make-responsive($desktop-sm) {
           		width: 35%;
        	}
    		&_label{
            	color: $brand-color-2;
            	margin-bottom: 0rem;
            	font-weight: normal;
        	}
        	.form-control{
        		padding: 0rem;
        	}
    	}
    }
    
    &__bkg-ref,&__bkg-ref-product-detail{
        padding: 0.3rem calc($base-padding/3);
    }
    &__bkg-ref{
        @include make-responsive($desktop-sm) {
            @include make-one-fourth;
            padding: 0.3rem calc($base-padding/3);
        }
    }
    &__bkg-ref-product-detail{
        @include make-responsive($desktop-sm) {
            @include make-three-fourth;
            padding: 0.3rem calc($base-padding/3);
        }
    }
    
    &__task-desc {
        &_data {
            max-height: none;
        }
    }
    
    &__assignee{
    	width: 100%;
    	float: left;
    	padding: .3rem .625rem;
    	@include make-responsive($desktop-sm) {
            width: 25%;
        }
    }
    
    &__category {
        @include make-responsive($desktop-sm) {
            width: 30%;
        }
    }

    &__details {
        padding: 0.25rem 0;
        &_text {
            width: 100%;
            padding: 0.5rem;
            border: 1px solid $brand-color-10;
            margin-top: 0.5rem;
            @include clearfix;

            .text {
                &__title {
                    float: left;
                    width: 90%;
                }

                &__icon,
                &__icon-active {
                    @include make-button($brand-color-5,$light, capitalize, $brand-color-2, $light);
                    float: right;
                    width: 10%;
                    text-align: right;
                }
                
                &__icon-active {
                    @include make-button($brand-color-2, $light,capitalize);
                    padding: 0rem;
                }
            }
        }
        
        &_text-active {
            margin-top: 0rem;
            border: none;
            padding: 0rem;
            border-bottom: 1px solid $brand-color-10;
            font-weight: bold;
        }
    }
    
    select {
        color: inherit;
        border-radius: 0px;
        font-size: inherit;
    }
    &__save-button{
        @include make-button($brand-color-11, $brand-color-1,capitalize, $brand-color-11, darken($brand-color-1,20));
        @include button-size(0.25rem, 0.25rem, $font-size-h4, inherit, 0px, 100%); 
        float: right;
        margin-top: 0.5rem;
        @include make-responsive($desktop-sm) {
            width: 30%;
        }
    }
}