.pos-container {
	
	@include create-main-wrapper;
	background-color:$brand-color-32;
	min-height: 100vh;
	&_wrapper {
      @include clearfix;
    }

	&__menu {
		
	}
	
	&__body {
	    margin-top: 1.5rem;
	    font-size: 400;
	}
}