@import "../../../../../../../../stylesheets/modules/styles/modules/pos/master/trans-manager/pos-remote-filter/pos-remote-filter";

.remote-filter {
	&__form {
		border-color: $brand-color-30;
		label{
			color: $brand-color-30;
		}
		&_more-filters-btn {
			color: $brand-color-30;
		}
		&_remote-search-btn > button {
			border-radius: 0.5rem;
		}
	}
   	&__reset-button {
		&_reset-all {
			@include make-button($brand-color-11, $brand-color-29, capitalize);
		}
   	}
   	
   	&__add-button {
		>button {
			@include make-button($brand-color-11, $brand-color-37, capitalize);
		}
	}
}