.remote-filter {
    &__list-button {
        float: right;
        margin-left: 10px;

        .remote-filter-list_button {
            @include make-button($brand-color-11, $brand-color-37, capitalize);
            @include button-size(10px, 10px, 15px, 0, 4px, 100%);
            height: 1.875rem;

            &-label {
                float: left;
                padding-right: .3rem;
                line-height: .5;
            }
        }

        .remote-triangle {
            position: relative;
            top: 0.125rem;
            margin-left: 0.2rem;
            float: left;
            @include make-triangle(bottom, 0.4375rem, $light);
        }

        .remote-triangle-active {
            position: relative;
            top: -0.5rem;
            margin-left: 0.2rem;
            float: left;
            @include make-triangle(top, 0.4375rem, $light);
        }
    }

    &-item {
        @include clearfix;
        border-bottom: 1px solid lighten($brand-color-5, 30);
        padding: 10px;
        margin: 0px -10px;
        min-width: 200px;

        &__default-icon {
            float: left;
            color: $brand-color-2;
            cursor: pointer;
            width: 10%;
        }

        &__name {
            padding-left: 0.3rem;
            float: left;
            width: 80%;
            cursor: pointer;
        }

        &__delete-icon {
            float: right;
            width: 10%;
        }

        &__default-icon.disabled,
        &__delete-icon.disabled {
            cursor: not-allowed;
            opacity: .65;
        }
    }
}