.pos-footer {
    display: flex;
    &__frame {
        width: 100%;
        height: 300px;
        overflow: hidden;
        border: 0;
        min-width: 1024px;	
    }
}
