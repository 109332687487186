@import "../../../../../../../../stylesheets/modules/styles/modules/pos/master/trans-manager/pos-refund-details/pos-refund-details";
.pos-refund-details{
	&__content{
	    &_heading{
			color: $brand-color-37;
		    font-weight: 600;
	    }
	}
	&__header {
		a {
			color: $brand-color-30;
			&:hover {
				color: darken($brand-color-30, 10);
			}
		}
	}
	label{
		color : $brand-color-30;
	}
}
