.pos-menu {

	@include create-main-wrapper;
    @include clearfix;
	
	&_wrapper {
      @include clearfix;
    }
	
	&__search-bar {
		@include make-row;
		float: left;
		width: 20%;
		.form-control{
			height:2rem;
		}
	}
	
	&__logo {
		float: left;
		padding: .9375rem .9375rem .9375rem 0;
		
		&-image {
			max-height: 48px
		}
	}
	
	&__nav{
		@include make-row;
		&_alert-warning {
  		 width: 55%;
  		}
		&_primary-nav, &_secondary-nav {
			&__offline-menu{
			 font-size: 14px;
			}
		}
	}
	
	&__nav{
		@include make-row;
		&_primary-nav, &_secondary-nav {
			float:left;
		}
	}
	
	&__nav-primary{
		@include make-row;
		//background-color: $brand-color-11;
	    margin-top: 1px;
		&_nav{
			@include make-row;
			width:80%;
			float:left;
		}	    
	}
	
	&__nav-mid-div{
		margin: 0 auto !important;
   		width:100%;
   		position: relative;
    	background-color: $brand-color-30 !important;
    	height: 6px;
	}
	
	&__nav-secondary{
		@include make-row;
		background-color: $brand-color-28;
	}


	.primary-nav{
		&__label{
 			display: block;
 			height: 2rem;
			padding: 5px 10px;
		    text-align: center;
		    background-color: $brand-color-30;
    		border-radius: 5px 5px 0 0 !important;
   			color: $brand-color-27 !important;
 			font-size: 12px !important;
   			margin-right: 2px !important;
    		margin-bottom: 0px !important;
    		padding: 6px 19px 5px !important;
    		margin-top: 0 !important;
		    &_active{
			    background-color: $brand-color-28;
		        font-weight: bold;
		       
			}
		}
		&__blink{
		   animation: blinker 0.8s linear infinite;
           color: #FFFFFF;
           @keyframes blinker {  
            50% { opacity: 0; }
          }
		}
		
		&__extra{
		    display: block;
 			height: 2rem;
			padding: 5px 10px;
		    text-align: center;
		    background-color: $brand-color-42;
    		border-radius: 5px 5px 0 0 !important;
   			color: $brand-color-27 !important;
 			font-size: 12px !important;
   			margin-right: 2px !important;
    		margin-bottom: 0px !important;
    		padding: 6px 19px 5px !important;
    		margin-top: 0 !important;
		    &_active{
			    background-color: $brand-color-28;
		        font-weight: bold;
		       
			}
		}
		.popover{
		 font-size:12px;
		 }
	}
	
	.primary-nav{
		float:left;
		&__label:hover{
	    		background-color: $brand-color-28;
	    	}		
	    &__label_active{
		        background-color: $brand-color-28;
	    }
	}

	.secondary-nav{
		&__label{
			padding: 3px 16px !important;
   			text-decoration: none;
    		vertical-align: middle;
    		font-size: 12px !important;
    		margin: 5px 0 0 5px !important;
			text-align: center;
			height: 1.88rem;
 			&_active{
			    background: $brand-color-38;
      			border-radius: 3px !important;
    			border: 1px solid #dcdcdc;
    			color: $brand-color-34 !important;
		       
			}
		}	
	}
	
	//.secondary-nav{
	//	float:left;
	//	&__label:hover{
//    		border-bottom: 5px solid $brand-color-30;
	 //   	}		
	//    &__label_active{
	//	        border-bottom: 5px solid $brand-color-30;
	   // }
	//}
	
	a{
		display:inline-block;
		text-decoration: none;
		font-weight: bold;
		color : $brand-color-30;
	}
	a:hover{
		color: $brand-color-34;
		text-decoration: none;
		font-weight: bold;
	}	
	
	a::after {
	    display:block;
	    content:attr(title);
	    font-weight:bold;
	    height:1px;
	    color:transparent;
	    overflow:hidden;
	    visibility:hidden;
	    margin-bottom:-1px;
	}	
	
}