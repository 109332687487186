.pos-trans-manager-details {
	&__header{
		@include make-row;
		text-transform: capitalize;
  		font-size: 1rem;
  		color: $brand-color-2;
		
		&_link{
			width:50%;
			float:left;
			&:hover{
				cursor: pointer;
			}	
		}
		&_title{
			width:50%;
			float:left;
		}
	}
	
	.booking__screen_card{
		width: 100%;
	}
	
	.link__action{
    	text-align: right;
		float: right;
	}
	
	.panel-heading{
		padding: 0;
		background-color: $brand-color-11;
	}
	.panel-body{
		padding: 0;
	}
	.panel-group .panel-heading+.panel-collapse>.panel-body{
	    border-top: none;
	}	
	.panel-group {
		.panel{
		    margin-bottom: 6px;
		}	
	}
	.panel-group{
		margin-bottom: 0; 
	}
	.customer__notes-description, .product-summary, .fare-rules__value, .booking-invoice__link{
		> a {
			color: $brand-color-1;
		}
		
		&__secondary-invoice{
			> a {
				color: $brand-color-28;
			}
			cursor: pointer;
		}
		
	}
	.modal-content {
	    margin-top: 15rem;
	}	
	button{
		border:none;
	}
	label{
		color: $brand-color-2;
	}
}
.pos-app-content{
	.modal__content{
		padding:0;
		.panel-heading{
			padding:0;
		}
		.panel-body{
		    font-size: 12px;
		    padding: 0;
		}
	}
}

.secondary-invoice-modal{
    	max-width: 35%;
     	margin-left: 30%;
     	margin-top: 10%;
     	font-size: 0.75rem;
     	font-weight: 600;
     	text-align: center;
    		 &__header{
    			 margin-top: 0.438rem;
    			 font-size: 1.1rem;
    			 background-color: $brand-color-32;
    			 height: 2rem;
    			 padding: 0.313rem;
    			 padding-bottom: 2rem;
    			 text-align: left;
    			 color: $brand-color-21;
    		 }
     	&__container {
     		box-sizing: inherit;
     		height : 11rem;
 	    }
     	&__content {
 	    	overflow-y: auto;
     		margin-bottom: 1rem;
     		.mandatory-input-field {
           		color: $brand-color-28;
           		font-size: $font-size-base*0.5;
           		vertical-align: text-top;
        		 }
     		&_label{
 		   		display: block;
     			padding-top: 0.1rem;
     			padding-right: 0.75rem;
     			padding-bottom: 0px;
     			padding-left: 0px;
     			margin-top: 0.438rem;
     			margin-bottom: 0.438rem;
     			color:$brand-color-2;
     			width: 100%;
     			text-align: left;
     			font-size: .875rem;
     			font-weight: 600;
 				}
 			&_input-field{
 				width: 100%;
     			max-height: 2.125rem;
 			}
 	
 	    }
 	    &__save-button{
 	    	@include make-button($light,$brand-color-21,capitalize,$light,$brand-color-21);
     	     width: 17%;
 			 padding: $base-padding*0.2;
 			 border: none;
 			 float: right;
 			 cursor: pointer;
 			 &:disabled {
               	cursor: not-allowed;
               	opacity: 0.65;
            		box-shadow: none;
         	}
 	    }
 	    &__close-button{
 	    	@include make-button($light,$brand-color-21,capitalize,$light,$brand-color-21);
 	    	 width: 17%;
 			 padding: $base-padding*0.2;
 			 border: none;
 			 float: left;
 			 cursor: pointer;
 	    }
 	    
 	    &__validation-error{
   			width: 100%;
     		color: $brand-color-1;
     		text-transform: none;
     		font-size: .75rem;
     		text-align: left;
     		font-weight: 600;
 		}
 }