@import "../../../../../../../../../stylesheets/modules/styles/modules/pos/master/trans-manager/pos-invoice/pos-invoice-list/_pos-invoice-row";

.pos-invoice-row{
    &__booking-row {
   		&:hover {
    		color: black;
			background-color: $brand-color-36;
			cursor : pointer;
    	}
   	}
}