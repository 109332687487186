@import "../../../../../../../../stylesheets/modules/styles/modules/pos/master/trans-manager/pos-refund-list/pos-refund-list";
.pos-refund-list{
	.refund-list{
		&_row {
			&:hover {
				background-color: $brand-color-36;
	    	}
		}
	}

	&__content{
		&_headers{
			background-color: $brand-color-36;
		    color: $brand-color-34;
		}
	}
}