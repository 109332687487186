@import "../../../../../../../stylesheets/modules/styles/modules/pos/auth/pos-login-form/pos-login-form";

.pos-reset-password-form,
.pos-login-form {
	font-size: 1rem;
	font-family: $font-family-landing-page-sans-serif;
	.pos-error-message {
		position: relative;
		width: 100%;
		margin: 0;
		top: 0.5rem;
		right: 0;
	}
    &__title {
    	font-size: 1.5rem;
    }
	&_card {
	    margin: 0;
    	padding: 1rem 3rem;
        background-color: var(--login-card-bg-color);
	}

	&__header{
   		 color: var(--login-card-label-color);
   		 &_text{
		    font-size: 1.5rem;
			font-weight: 500;
   		 }
	}
	&__content{
		margin: $base-padding*0.5 0;
	}	
	
	&_wrapper {
	    margin: auto;
	    width: 350px;
	    height: auto;
	}
	&_register{
 	 	width: 110px;
    	height: 110px;
    	z-index: 10;
    	position: absolute;
    	right: 4.6rem;
    	bottom: 10.1rem;
    	padding-top: 2.5rem;
 	}
 	
 	&_register-link{
 		color: $brand-color-27;
    	font-size: 1.375rem;
    	text-align: center;
    	display: inline-block;
    	padding-top: 30px;
    	font-weight: 600;
 	}
	
	&_you-tube{
		width: 110px;
		height: 110px;
		z-index: 10;
    	position: absolute;
    	right: 26.4rem;
	}
	
	&_you-tube-link{
		color: $brand-color-27;
		font-size: 1.375rem;
		text-align: center;
		display: inline-block;
		font-weight: 600;
 		img {
     		filter: grayscale(0);
     		transition: all .4s ease-in-out;  
     	}
    	img:hover {
    		filter: none;
      		box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
      		filter: grayscale(1);
			transform: scale(1);
		}
	}
	
	&_gm-logo{
		height: 110px;
		z-index: 10;
    	position: absolute;
    	right: 53.5rem;
    	top: 29.5rem;
	}
	
	&_gm-logo-image{
		color: $brand-color-27;
		font-size: 1.375rem;
		text-align: center;
		display: inline-block;
		font-weight: 600;
	}
	} 
	    

.pos-reset-password-form {
	display: flex;
	min-height: 600px;
	background-image: url(../images/dnata/banner-image.jpg);
	border-bottom: 1rem solid $brand-color-31;
}

.reset-password__page,
.login__page {
  &_input_wrapper {
	input[type="text"],
  	input[type="email"],
  	input[type="password"] {
		padding: 0.75rem 1rem;
		font-family: $font-family-sans-serif;
		font-weight: 100;
		border-color: var(--login-card-label-color);
	}
  label {
    color: var(--login-card-label-color);
	font-size: 0.9375rem;
	font-weight: 600;
  }
  }
  &_button {
    background-color: var(--login-card-button-bg-color);
    &:hover {
      background-color: var(--login-card-button-hover-bg-color);
    }
	&:disabled {
	  background-color: var(--login-card-button-disabled-bg-color);
	  &:hover {
	    background-color: var(--login-card-button-disabled-bg-color);
	  }
	}    
  }
  
	&_links{
    	a {
	        color:var(--header-links-color);
			margin-right: 0;
			font-weight: 600;
			&:focus,
			&:hover {
		        color:var(--header-links-color);
			}
	    }
    }

	&_input-group-addon {
		border-right: 0;
		color: var(--header-links-color);
		border-color: var(--header-links-color);
		padding: 0.375rem;	
	}

}

.wrapper{
	&__error{
		color: $brand-color-29;
	}
	
	&__error-asu-login{
		color: darken($brand-color-29, 5%);
		font-weight:600;
		font-size: $font-size-h5;
	}
	&__password-help-text{
		font-size: 0.75rem;
		color: var(--header-font-color);
	}
}
