@import "../../../../../stylesheets/modules/styles/components/routed-popover/routed-popover";

.routed-popover {
	&__route-link {
		color: var(--popover-link-font-color);
		&:hover {
			color: var(--popover-link-hover-color);	
		}
	}
    &__view {
		left: 30%;
	    top: 3.5rem;
        &:before {
            border-bottom-color: var(--popover-triangle-color);
        }
    }
}