@import "../../../../../../../../../stylesheets/modules/styles/modules/pos/master/trans-manager/queue-management/pos-queue-management-remote-filter/pos-queue-management-remote-filter";
.pos-queue-management-remote-filter {
	&__reset-button {
		>button {
			background-color: $brand-color-1;
			&:hover {
				background-color: darken($brand-color-1, 10);
			}
		}
	}
	&__add-button {
		>button {
			background-color: $brand-color-37;
			&:hover {
				background-color: darken($brand-color-37, 10);
			}
		}
	}
	&__form {
		border-color: $brand-color-2;
	}
}