.pos-app {
	margin: auto;
    font-weight: 400;
}
.pos-auth-content{
	background-color: $brand-color-32;
}

.pos-app-content{
    overflow-y: scroll;
}
.pos-error-message {
	.alert {
		border: 0;
		border-left: 5px solid;
		border-radius: 0;
	    font-weight: 600;
	}
}