.pos-task-list {
	@include create-main-wrapper;

	&_wrapper {
	    @include clearfix;
	}
	.priority{
		&__medium, &__high, &__low{
			font-size: 1.25rem;
		}
		&__medium{
			color: $star-rating;
		}
		&__high{
			color: $brand-color-1;
		}
		&__low{
			color: $brand-color-2;
		}
	}
	
    &__container {

        &_header {
            @include clearfix;
            margin-bottom: calc($base-margin/2);
            box-shadow: 0 0 10px rgba(0, 0, 0, .25);
            background-color: $brand-color-36;
            text-transform: capitalize;
            color: $brand-color-34;
            display: none;
            width: 100%;
            padding: calc($base-padding/3);
            @include make-responsive($desktop-sm){
                display: block;
            }
        }
        
        &_content {
            float: none;
            width: 100%;
            @include make-responsive($desktop-sm){
                float: none;
                width: 100%;
                padding: 0rem;
            }
        }
        
        &_content:nth-child(odd) {
            @include make-responsive($desktop-sm){
                padding: 0rem;
            }

        }
        &_content:nth-child(even) {
            @include make-responsive($desktop-sm){
                padding: 0rem;
            }
        }
        
        .icons {
            @include make-responsive($desktop-sm) {
                display: none;
            }
        }
    }

    .header {
        &__task-title, &__link-info, &__status-info {
            @include make-block;
            padding: 0;
        }
        &__task-title {
            width: 30%;
        }
        &__link-info {
            width: 45%;
            &_assignee {
                width: 40%;
                float: left;
            }
            &_bkg-no {
                width: 60%;
                float: left;
            }
        }
        &__status-info {
            width: 25%;
            &_date {
                width: 60%;
                float: left;
            }
            &_status {
                float: left;
            }
        }
    }

    .content {
        @include create-row-card;
        @include create-card-content;
        &_card {
            @include clearfix;
            box-shadow: $row-shadow-color;
            padding: 0;
        }
        &_row {
            @include clearfix;
            color: $gray-dark;
            padding: calc($base-padding/3);
            &:hover {
                color: $dark;
                background-color: $brand-color-3;
            }
            cursor: pointer;
        }
        
        &__details {
            @include clearfix;
            &_task-title, &_link-info, &_status-info, &_task-created {
                @include clearfix;
                width: 100%;
                margin: 0 0 0.25rem 0;
                @include make-responsive($desktop-sm) {
                    margin: 0;
                }
            }

            &_task-title {
                font-size: 1.25rem;
                font-weight: bold;
                
                @include make-responsive($desktop-sm) {
                    width: 30%;
                    float: left;
                    font-weight: 400;
                }
                .task-title{
                    &__task-number{
                        @include make-responsive($desktop-sm) {
                            float: left;
                        }
                    }
                    &__task-name{
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }
            }
            &_task-title,
            &_link-info {
                @include make-responsive($desktop-sm) {
                     font-size: $font-size-h5;
                }
            }
            
            &_link-info {
                @include make-responsive($desktop-sm) {
                    width: 45%;
                    float: left;
                }
                .link-info {
                    &__task-assignee {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        text-transform: capitalize;
                        @include make-responsive($desktop-sm) {
	                        float: left;
	                        width: 40%;
                        }
                    }
                    &__task-booking {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        .fa-stack{
                        	width:inherit;
                        }
                        @include make-responsive($desktop-sm) {
	                        float: left;
	                        width: 60%;
                            text-align: left;
                        }
                    }
                }
            }

            &_status-info {
                @include make-responsive($desktop-sm) {
                    width: 25%;
                    float: right;
                }
                .status-info {
                    &__task-due {
                        width: 50%;
                        float: left;
                        &_today-due{
                            color: lighten($brand-color-1, 10%);
                        }
                        &_over-due{
                            color: darken($brand-color-1, 20%);
                        }
                        @include make-responsive($desktop-sm) {
	          	            width: 60%;
                            font-size: 1rem;
                        }
                    }
                    &__task-status {
                        color: $light;
                        font-weight: bold;
                        padding: 2px 4px;
                        border-radius: 3px;
                        float: right;
                        min-width: 15%;
                        text-align: center;
                        @include make-responsive($desktop-sm) {
                            float: left;
                            width: 40%;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}