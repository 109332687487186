@import "../../../../../../../../../stylesheets/modules/styles/modules/pos/master/trans-manager/queue-management/pos-queue-pnr-action-form/pos-queue-pnr-action-form";

.pos-queue-pnr-action-form {
	&__control-buttons {
		&_action,
	    &_close {
			background-color: $brand-color-28;
			&:hover {
				background-color: darken($brand-color-28, 10);
			}
			&:disabled {
				background-color: lighten($brand-color-28, 20);
				cursor: not-allowed;
			}
		}
	}
}