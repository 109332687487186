@mixin form-row-container-properties_private {
	padding-top: 0.3rem;
	padding-bottom: 0.3rem;

	&_label {
		color: $brand-color-30;
		margin-bottom: 0;
	}

	&_data {
		width: 100%;
		padding: 0.5rem 1rem;
		vertical-align: middle;
		line-height: normal;
		border: 1px solid $brand-color-10;
		max-height: 34px;
	}
}
@mixin create-form-row-container_private {
	&__container {
		@include clearfix;
		@include make-one-third;
		@include form-row-container-properties;
	}

}

@mixin create-form-row-details_private {
   	&__details{
   		@include clearfix;
   		padding-bottom: 1rem;
   	
   		&_wrapper{
			@include clearfix;
   		
   		}
   		&_text{
		    font-size: 1.2rem;
		    padding: 1rem 1rem 0 1rem;  
		    color: $brand-color-2; 	
  	 	}
   	}	
	
}

@mixin  create-form-row-container { 
	@include create-form-row-container_private; 
}
@mixin  create-form-row-details { 
	@include create-form-row-details_private; 
}
@mixin  form-row-container-properties { 
	@include form-row-container-properties_private; 
}