.registerForm{
 	background-color: $brand-color-40;
    padding-top: 1.1rem;
    width: 34.1rem;
    height: 41.625rem;
    position: absolute;
    padding-left: 1.68rem;
    margin-left: 16.12rem;
	font-family: $font-family-landing-page-sans-serif;
    margin-top: 0;
    &__firstName,&__lastName,&__email,&__company,&__phone,&__city,&__country,&__type_of_company{
	    width: 15rem;
	    color: $brand-color-34;
	    width: 26rem;
	    margin-left: 1.5rem;
	    margin-top: 1rem;
	&_label{
		 color:$brand-color-27;
	     font-size: 0.875rem;
	     margin-left: 2rem;
		 font-weight: 600;
     }
     &_input-field{
     	float: right;
     	input{
     		padding: 0.3rem;
    		width: 15rem;
    		margin-top: -1rem;
     	}
     	}
     }
    &__type_of_company{
    	&_input-field{
     		float: right;
 	    	margin-right: 6rem;
     		input{
    			width: 1rem;
     		}
     	 }
    }
    &__recaptcha_check{
		float: right;
    	padding-right: 10rem;
	}
  	&__submit{
  		&_button{
	 	 width: 9.375rem;
	     height: 2.815rem;
	     margin-left: 10.437rem;
	     margin-top: 1rem;
	     color: $brand-color-27;
	     background-color: $brand-color-39;
	     font-size: 1.187rem;
		 border : none;
		 font-weight: bold;
	     }
	     .registerForm:valid &_button{
  			background : $brand-color-39;
  			cursor: pointer;
		}
	     .registerForm:invalid &_button{
  			cursor: not-allowed;
		}
 	}   
 	&__form_field_validation_error{
	 	color:$brand-color-27;
	 	margin-left: 12.5rem;
 	}
 	&__recaptcha_validation_error{
	 	color:$brand-color-27;
	 	margin-left: 12.5rem;
	 	float: left;
 	}
}
 
 .pos-error-message{
   margin-left: 13.312rem;
 }
 .pos-register-form{
 	 position: relative;
     min-height: 41.8rem;
     background-position-x: center;
     background-repeat: no-repeat;
	 display: flex;
	 justify-content: center;
 }