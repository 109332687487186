@import "../../../../../../../../stylesheets/modules/styles/modules/pos/master/task-manager/pos-deposit-note-list/pos-task-list";


.pos-deposit-note-list  {
    &__container {

        &_header {
            background-color: $brand-color-36;
            color: $brand-color-34;
            }
        }
    }
    
    .content {
        &_row {
            color: $brand-color-34;
        }
}